import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { nav } from '../../data/nav';
import "./style.css";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('ReservationSystemAcc');
    window.location.reload();
  };

  // التحقق مما إذا كان المستخدم قد قام بتسجيل الدخول
  const isLoggedIn = localStorage.getItem("ReservationSystemAcc");

  return (
    <nav>
      {/* زر "هامبورجر" لفتح/إغلاق القائمة */}
      <div className="hamburger-menu" onClick={handleToggleMenu}>
        <div
          className={
            isMenuOpen ? "hamburger-menu-icon open" : "hamburger-menu-icon"
          }
        ></div>{" "}
        <div
          className={
            isMenuOpen ? "hamburger-menu-icon open" : "hamburger-menu-icon"
          }
        ></div>{" "}
        <div
          className={
            isMenuOpen ? "hamburger-menu-icon open" : "hamburger-menu-icon"
          }
        ></div>
      </div>

      {/* القائمة */}
      <ul className={isMenuOpen ? "nav-list open" : "nav-list"}>
        {nav?.map((item, index) => {
          return (
            <li key={index} className="nav-item">
              <NavLink
                to={item?.to}
                className="nav-link"
                onClick={handleToggleMenu}
              >
                {item?.label}
              </NavLink>
            </li>
          );
        })}
        {/* إضافة زر "تسجيل الخروج" */}
        {isLoggedIn && (
          <li className="nav-item">
            <button className="nav-button" onClick={handleLogout}>
              تسجيل الخروج
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default Header;
