import React from 'react';
import Header from '../header';

const DefaultLayout = ({ children }) => {
  return (
    <div className="defaultLayout">
      <Header />
      {children}
    </div>
  );
};

export default DefaultLayout;
