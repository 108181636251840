import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";
import 'rsuite/dist/rsuite.min.css';
import "./index.css";
import DefaultLayout from "./layouts/defaultLayout";

import LogIn from "./pages/login";
import Books from "./pages/books";

function App() {
  return (
    <div className="App">
      <DefaultLayout>
        {/* {localStorage?.getItem("ReservationSystemAcc") ? ( */}
          <Routes>
            <Route path="*" element={<Books />} />
            <Route path="/Books" element={<Books />} />
            
          </Routes>
        {/* ) : (
          <Routes>
            <Route path="*" element={<LogIn />} />{" "}
          </Routes>
        )} */}
      </DefaultLayout>
      <Toaster containerClassName="toaster" />
    </div>
  );
}

export default App;
