import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Input, Modal, Tooltip, Whisper } from "rsuite";
import "./style.css";
import toast from "react-hot-toast";

const Books = () => {
  const [books, setBooks] = useState([
    {
      book_code: "test_book2",
      book_title: "test",
    },
    {
      book_code: "test_book2",
      book_title: "Minya 3rd year book - I",
    },
  ]);

  const [email, setEmail] = useState("");

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        'https://camp-coding.online/elmatary_books/get_books_list.php'
      );
      setBooks(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleShowEmail = (bookCode, event) => {
    event.preventDefault();
    const url = `https://camp-coding.online/elmatary_books/print_book.php?email=${email}&book_code=${bookCode}`;
    if (email && bookCode) {
      window.open(url, '_blank');
      setBookView(null);
    } else toast.error("يجب عليك إدخال الإيميل");
    setEmail(null);
  };

  const [bookView, setBookView] = useState(null);

  return (
    <div className="App">
      <header>
        <h1>منصة الكتب - الريس محيي</h1>
      </header>

      <div className="books-container">
        {books.map((book, index) => (
          <div
            key={index}
            className={
              bookView && book.book_code == bookView?.book_code && book.book_title == bookView?.book_title
                ? "book-card active"
                : "book-card "
            }

            onClick={()=>setBookView(book)}
          >
            <h2>{book.book_title}</h2>
            <p>{book.book_code}</p>
            {/* <Whisper
              trigger="click"
              placement="topStart"
              controlId={`control-id-topStart-${index}`}
              speaker={
                <Tooltip>
                  <div className="tooltip-content">
                    <Input
                      placeholder="أدخل البريد الالكتروني"
                      value={email}
                      onChange={(value) => setEmail(value)}
                    />
                    <Button
                      appearance="primary"
                      onClick={(event) =>
                        handleShowEmail(book.book_code, event)
                      }
                      className="send-button"
                    >
                      إنشاء نسخة
                    </Button>
                  </div>
                </Tooltip>
              }
            >
              <Button
                appearance="subtle"
                className="view-button"
                onClick={() => setBookView(book)}
              >
                إنشاء نسخة
              </Button>
            </Whisper> */}
            <Button
              appearance="subtle"
              className="view-button"
              onClick={() => setBookView(book)}
            >
              إنشاء نسخة
            </Button>
          </div>
        ))}
      </div>
      <Modal show={bookView} open={bookView} onClose={() => setBookView(null)}>
        <Modal.Header>إضهار الكتاب ({bookView?.book_title})</Modal.Header>
        <Modal.Body>
          <div className="tooltip-content">
            <Input
              placeholder="أدخل البريد الالكتروني"
              value={email}
              onChange={(value) => setEmail(value)}
            />
            <Button
              appearance="primary"
              onClick={(event) => handleShowEmail(bookView.book_code, event)}
              className="send-button"
            >
              إنشاء نسخة
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Books;
